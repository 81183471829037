import Vue from "vue";
import Controller from "../store/controller";
import { User } from "./models";
import md5 from "md5";
import axios from "axios";
import * as turf from "@turf/turf";
import i18n from "@/plugins/lang";
import { KITE_CONTACT } from "@/global";
import { getBinaryName } from "@/functions-tools";
import { loadMapView } from "@/map_view";

export class BinaryAlreadyAdded extends Error {
  binary_info: any;
  constructor(binary_info: any, message?: string) {
    if (message == undefined) {
      message = i18n.t("project.binary_already_added", { name: getBinaryName(binary_info) }).toString();
    }
    super(message);
    this.binary_info = binary_info;
  }
}

export type ProjectPermission = "Editor" | "Viewer" | "all";
/**
 * This should be part of a generic module to be reused
 */

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/**
 * use whale auth
 */
export const useWhale = (options: any) => {
  if (instance) return instance;
  const controller = Controller.getInstance();
  controller.setStore(options.store);
  instance = new Vue({
    data() {
      return {
        authenticating: true,
        isAuthenticated: false,
        user: undefined,
        controller,
        error: null,
        project: {
          name: ""
        },
        async: {
          updateUser: false,
          updateProject: false
        },
        map: null
      };
    },
    methods: {
      /**
       * Login retrieve the user or redirect to auth.tellae.fr
       * if the user is not logged in
       */
      async login() {
        let params = new URLSearchParams(window.location.search);
        let token = params.get("preset");
        if (token) {
          let preset_obj;
          try {
            preset_obj = await this.jwtLogin(token);
          } catch {
            this.authenticating = false;
            this.error = i18n.t("presets.jwt_login_error");
            return;
          }
          // set preset in store and act on it later, when map is loaded
          options.store.commit("SET_APP_PRESET", preset_obj);

          this.user = await this.controller.me();
          this.authenticating = false;
          this.isAuthenticated = this.user !== undefined;
          if (this.isAuthenticated === false) {
            let message = "Error while authenticating from preset";
            alert({ message, type: "error" });
            throw new Error();
          }
          // set project associated to map view
          await this.setCurrentProject(preset_obj.project, true);

          // set project's _application to "CONTEMPLATIVE" (maybe depends on preset type)
          this.project._application = "CONTEMPLATIVE";
          this.project._permissions = ["*"];
        } else {
          // function for basic kite configuration
          this.user = await this.controller.me();
          // if still logged in as guest, logout
          if (this.userIsGuest()) {
            this.logout();
            this.user = undefined;
          }
          this.authenticating = false;
          this.isAuthenticated = this.user !== undefined;
          if (this.isAuthenticated === false) {
            setTimeout(() => {
              this.controller.authRedirect();
            }, 1500);
          } else {
            this.user.kite ??= {};
            this.user.kite.project ??= this.user.uuid;
            // language config
            if (options.i18n && this.user.kite.locale) {
              options.i18n.locale = this.user.kite.locale;
              if (options.store) {
                options.store.state.language = this.user.kite.locale;
              }
            }
            // display window config
            /**
          this.user.kite.analysis ??= {};
          ["displayStatistics", "displayPlots", "displayLegends"].forEach(e => {
            if (this.user.kite.analysis[e] !== undefined) {
              options.store.state.analysis[e] = this.user.kite.analysis[e];
            }
          });
          */
            // map config
            this.user.kite.mapProjects ??= {};
            if (this.user.kite.mapProjects[this.user.kite.project]) {
              ["zoom", "center", "pitch"].forEach(e => {
                if (this.user.kite.mapProjects[this.user.kite.project][e] !== undefined) {
                  options.store.state.map[e] = this.user.kite.mapProjects[this.user.kite.project][e];
                }
              });
            }
            // capucine config
            this.user.kite.capucineProjects ??= {};
            if (this.user.kite.capucineProjects[this.user.kite.project]) {
              if (this.user.kite.capucineProjects[this.user.kite.project].analysis) {
                for (const key in this.user.kite.capucineProjects[this.user.kite.project].analysis) {
                  options.store.state.capucine_analysis[key] =
                    this.user.kite.capucineProjects[this.user.kite.project].analysis[key];
                }
              }
              if (this.user.kite.capucineProjects[this.user.kite.project].simulation) {
                for (const key in this.user.kite.capucineProjects[this.user.kite.project].simulation) {
                  options.store.state.capucine_simulation[key] =
                    this.user.kite.capucineProjects[this.user.kite.project].simulation[key];
                }
              }
              if (this.user.kite.capucineProjects[this.user.kite.project].results) {
                for (const key in this.user.kite.capucineProjects[this.user.kite.project].results) {
                  options.store.state.capucine_results[key] =
                    this.user.kite.capucineProjects[this.user.kite.project].results[key];
                }
              }
            }

            try {
              await this.setCurrentProject(this.user.kite.project, true);
            } catch (err) {
              if (err.http && err.http.status) {
                // Display a message to the user
                let message = `You have lost access to the project ${this.user.kite.project}`;
                alert({ message, type: "warning" });
                // Fallback on user project
                await this.setCurrentProject(this.user.uuid);
              }
            }
          }
        }
      },

      userIsGuest() {
        return this.user !== undefined && this.user.uuid.startsWith("jwt_");
      },

      /**
       * Set the current project for the UI
       * @param uuid
       * @param noUpdate prevent the save
       */
      async setCurrentProject(uuid: string | Object, noUpdate: boolean = false) {
        if (!noUpdate) {
          this.user.kite.project = uuid;
          // Do not wait for the end of promise
          this.updateUser(["kite"]);
        }
        if (typeof uuid === "string") {
          this.project = await this.controller.getProject(uuid);
        } else {
          this.project = uuid;
        }
        if (options.store.state.mapInitialized) {
          if (this.map && this.user.kite.mapProjects[this.project.uuid]) {
            this.map.jumpTo(this.user.kite.mapProjects[this.project.uuid]);
          }
        }
      },

      async downloadFromBinaries(info: string, raw: boolean = false) {
        // Retrieve URL as we cannot post credentials to S3
        let url = await this.controller.http(`/binaries/${info}/url`, {
          credentials: "include"
        });
        // Retrieve the flow
        const data = await fetch(url.Location, { credentials: "omit" });
        if (raw) {
          return data;
        }
        return data.json();
      },

      /**
       * Create a new project
       * @param uuid
       */
      async newProject(project: any) {
        // We do more than just one XHR for new project
        options.store.dispatch("asyncStart", "newProject");
        let res = await this.controller.http(`/projects`, { method: "POST", bodyObject: project });
        this.user._ownedProjects ??= [];
        this.user._ownedProjects.push(res);
        await this.setCurrentProject(res.uuid);
        options.store.dispatch("asyncEnd", "newProject");
      },
      /**
       * Create a new project
       * @param uuid
       */
      async setProjectAcl(project: any) {},
      /**
       * Create a new project
       * @param uuid
       */
      async getProjectAcl(project: any) {},
      /**
       * Logout from the application
       */
      async logout() {
        await this.controller.logout();
      },
      /**
       * Update a project
       */
      async updateProject(fields?: string[]) {
        if (this.async.updateProject) {
          clearTimeout(this.async.updateProject);
        } else {
          options.store.dispatch("asyncStart", "updateProject");
        }
        // Allow selective update
        this.async.updateProjectUpdates ??= {};
        if (fields) {
          fields.forEach(f => (this.async.updateProjectUpdates[f] = this.project[f]));
        } else {
          this.async.updateProjectUpdates = this.project;
        }
        this.async.updateProject = setTimeout(async () => {
          await this.controller.updateProject(this.project.uuid, this.async.updateProjectUpdates);
          this.async.updateProject = false;
          Object.keys(this.async.updateProjectUpdates).forEach(k => {
            this.project[k] = this.async.updateProjectUpdates[k];
          });
          this.async.updateProjectUpdates = {};
          options.store.dispatch("asyncEnd", "updateProject");
        }, 200);
      },

      async updateMapPreferences(map) {
        this.user.kite.mapProjects ??= {};
        this.user.kite.mapProjects[this.project.uuid] ??= {};
        this.user.kite.mapProjects[this.project.uuid] = {
          ...this.user.kite.mapProjects[this.project.uuid],
          ...map
        };
        return this.updateUser(["kite"]);
      },

      async updateCapucinePreferences(capucine_config) {
        this.user.kite.capucineProjects ??= {};
        this.user.kite.capucineProjects[this.project.uuid] ??= {};
        this.user.kite.capucineProjects[this.project.uuid].analysis ??= {};
        this.user.kite.capucineProjects[this.project.uuid].analysis = {
          ...this.user.kite.capucineProjects[this.project.uuid].analysis,
          ...capucine_config.analysis
        };
        this.user.kite.capucineProjects[this.project.uuid].simulation ??= {};
        this.user.kite.capucineProjects[this.project.uuid].simulation = {
          ...this.user.kite.capucineProjects[this.project.uuid].simulation,
          ...capucine_config.simulation
        };
        this.user.kite.capucineProjects[this.project.uuid].results ??= {};
        this.user.kite.capucineProjects[this.project.uuid].results = {
          ...this.user.kite.capucineProjects[this.project.uuid].results,
          ...capucine_config.results
        };
        return this.updateUser(["kite"]);
      },

      /**
       * Update current user
       * @param fields to update if not specified the whole user is sent
       */
      async updateUser(fields?: string[]) {
        if (this.async.updateUser) {
          clearTimeout(this.async.updateUser);
        } else {
          options.store.dispatch("asyncStart", "updateUser");
        }
        // Allow selective update
        this.async.updateUserUpdates ??= {};
        if (fields) {
          fields.forEach(f => (this.async.updateUserUpdates[f] = this.user[f]));
        } else {
          this.async.updateUserUpdates = this.user;
        }
        this.async.updateUser = setTimeout(async () => {
          await this.controller.updateUser(this.user.uuid, this.async.updateUserUpdates);
          this.async.updateUser = false;
          this.async.updateUserUpdates = {};
          options.store.dispatch("asyncEnd", "updateUser");
        }, 200);
      },

      /**
       * Test if access to feature is allowed
       * @param feature_name
       * @returns Boolean
       */
      allowFeatureAccess(feature_name) {
        return this.user._features.includes(feature_name);
      },

      /**
       * Test if current user has access to the given feature
       * @param feature accessed feature
       * @returns boolean indicating if user has access
       */
      hasAccess(feature) {
        return this.user._features.includes(feature);
      },

      /**
       * Run given function on given args if feature access is allowed
       * @param feature feature name
       * @param func function to run
       * @param arg function arg
       * @returns boolean indicating if access was granted
       */
      runIfHasAccess(feature, func, arg, message?) {
        let access = this.hasAccess(feature);
        let function_result;
        let displayed_message;

        if (access) {
          if (func) {
            function_result = func(arg);
          }
        } else {
          if (func) {
            if (message == undefined) {
              displayed_message = i18n.t("access_error", { mail: KITE_CONTACT });
            } else {
              displayed_message = message;
            }
            alert({ displayed_message, type: "warning" });
          }
          return false;
        }

        return {
          access,
          function_result,
          displayed_message
        };
      },

      // JWT functions

      async jwtLogin(token: string) {
        return this.controller.http(`/login/jwt`, {
          method: "PUT",
          bodyObject: {
            token
          }
        });
      },

      // Map view functions

      async createMapView(map_view: any) {
        return this.controller.http(`/map_views`, {
          method: "POST",
          bodyObject: {
            ...map_view,
            project: this.project.uuid
          }
        });
      },

      getMapView(uuid: string, options?: any) {
        return this.controller.http(`/map_views/${uuid}`, options);
      },

      updateMapView(uuid: string, updates: any) {
        return this.controller.http(`/map_views/${uuid}`, { method: "PATCH", bodyObject: updates });
      },

      shareMapView(uuid: string) {
        return this.controller.http(`/map_views/${uuid}/share`, { method: "PUT" }, true);
      },

      deleteMapView(uuid: string) {
        return this.controller.http(`/map_views/${uuid}`, { method: "DELETE" });
      },

      /**
       * Request capucine
       * @param url
       * @returns
       */
      async capucine(url: string, options: any = {}) {
        return this.controller.http(`projects/${this.project.uuid}/capucine/${url}`, options);
      },

      async getCapucineVersion() {
        return this.capucine(`version`);
      },

      async getCapucineLotsSummary() {
        return this.capucine(`lots_summary`);
      },

      async getCapucineDataBatches() {
        return this.capucine(`data_batches`);
      },

      async getCapucineLotInfo(lot: string, with_description: boolean = false) {
        return this.capucine(`lot?lot=${lot}&with_description=${with_description}`);
      },

      async getCapucineScenariosList() {
        return this.capucine(`scenario/list`);
      },

      async getCapucineScenarioSummary(scenario: number) {
        return this.capucine(`scenario/summary?scenario_id=${scenario}`);
      },

      async createCapucineScenario(scenario_data: any) {
        return this.capucine(`scenario/create`, {
          method: "POST",
          bodyObject: scenario_data
        });
      },

      async copyCapucineScenario(scenario_data: any) {
        return this.capucine(`scenario/copy`, {
          method: "POST",
          bodyObject: scenario_data
        });
      },

      async deleteCapucineScenario(scenario: number) {
        return this.capucine(`scenario/delete?scenario_id=${scenario}`, {
          method: "DELETE"
        });
      },

      async updateCapucineScenarioQS(scenario: number, qs_share: number) {
        return this.capucine(`scenario/update_qs?scenario_id=${scenario}&qs_share=${qs_share}`, {
          method: "PUT"
        });
      },

      async getCapucineScenarioParams(scenario: number, route_data_id: string, type: string) {
        return this.capucine(`scenario/params?scenario_id=${scenario}&route_data_id=${route_data_id}&day_type=${type}`);
      },

      async runCapucineSimulation(params: any) {
        return this.capucine(`scenario/run_simulation`, {
          method: "POST",
          bodyObject: params
        });
      },

      async getCapucineBaseValues(route_data_id: string, type: string, first_last: boolean) {
        return this.capucine(`parameters?route_data_id=${route_data_id}&day_type=${type}&first_last=${first_last}`);
      },

      async getCapucineSimulationResults(scenario: number) {
        return this.capucine(`scenario/results?scenario_id=${scenario}`);
      },

      async getCapucineSummary() {
        return this.capucine(`summary`);
      },

      async getCapucineSynthesis(lot: string) {
        return this.capucine(`kpi_synthesis?lot=${lot}`);
      },

      getCapucineKpis(route_data: number, day_type: string, travel_time_params?: any) {
        return this.capucine(`get_kpis`, {
          method: "POST",
          bodyObject: {
            route_data,
            day_type,
            travel_time_params
          }
        });
      },

      /**
       * Get travel time.
       */
      async getCapucineTravelTime(route_data: string, day_type: string, travel_time_params: any) {
        return this.capucine(`travel_time`, {
          method: "POST",
          bodyObject: {
            route_data,
            day_type,
            travel_time_params
          }
        });
      },

      /**
       * Get travel time all saved filters of the given route_data.
       * All database entries corresponding to the route_data id are returned,
       * allowing to navigate among contexts without additional requests.
       *
       * @param route_data_id
       * @returns list of saved contextual filters for the given route_data.
       */
      async getTravelTimeFilters(route_data_id: number) {
        return await this.capucine(`filters/get?route_data_id=${route_data_id}`);
      },

      /**
       * Save travel time filter for the given analysis context.
       *
       * @param route_data_id
       * @param day_type
       * @param direction
       * @param origin_stop
       * @param destination_stop
       * @param filter
       * @returns
       */
      saveTravelTimeFilter(
        route_data_id: number,
        day_type: string,
        direction: number,
        origin_stop: string,
        destination_stop: string,
        filter
      ) {
        return this.capucine("filters/save", {
          method: "POST",
          bodyObject: {
            route_data: route_data_id,
            day_type,
            direction,
            origin_stop,
            destination_stop,
            filter,
            author: this.user.uuid
          }
        });
      },

      /**
       * Load project acl
       */
      async loadProjectAcl() {
        this.project.__acl = await this.controller.loadProjectAcl(this.project.uuid);
      },

      /**
       * Invite an email on a project
       * @param email
       * @param permissions
       */
      async invite(email: string, permissions: ProjectPermission) {
        await this.controller.inviteOnProject(this.project.uuid, email, permissions);
        // Reload ACL
        await this.loadProjectAcl();
      },

      async updateAce(user: string, permissions: ProjectPermission) {
        await this.controller.updateAce(this.project.uuid, user, permissions);
        // Reload ACL
        await this.loadProjectAcl();
      },

      /**
       * Download a file from project
       * @param model
       * @param uuid
       * @param attribute
       * @param index
       */
      downloadProjectFile(attribute: string, index: number) {
        return this.downloadFile("projects", this.project.uuid, attribute, index);
      },
      /**
       * Download a file from the binary manager of whale
       * @param model
       * @param uuid
       * @param attribute
       * @param index
       */
      downloadFile(model: string, uuid: string, attribute: string, index: number) {
        window.open(this.controller.getUrl(`/binaries/${model}/${uuid}/${attribute}/${index}`), "_blank");
      },

      /**
       * Add a file on the current project
       * @param attribute
       * @param data
       * @param info
       * @param progressHandler
       * @returns project binary
       */
      async addProjectFile(
        attribute: string,
        data,
        filename: string,
        info: any = {},
        progressHandler?: (progress: number) => void
      ) {
        // add file to project binaries, or just get info if already added
        info = await this.addFile("projects", this.project.uuid, attribute, data, filename, info, progressHandler);

        // add to project if not already added
        let index = this.project[attribute].map(info => info.hash).indexOf(info.hash);
        if (index == -1) {
          this.project[attribute].push(info);
        } else {
          let binary_info = this.project[attribute][index];
          throw new BinaryAlreadyAdded(binary_info);
        }

        return info;
      },

      /**
       * Add a file using the binary manager of whale
       * @param model
       * @param uuid
       * @param attribute
       * @param data
       * @param info
       * @param progressHandler
       */
      async addFile(
        model: string,
        uuid: string,
        attribute: string,
        data,
        filename: string,
        info: any = {},
        progressHandler?: (progress: number) => void
      ) {
        let mimetype = "application/octet-stream";
        if (typeof data === "string") {
          data = new TextEncoder().encode(data);
        } else if (data instanceof Blob) {
          mimetype = data.type;
          data = await data.arrayBuffer();
        } else if (!(data instanceof Uint8Array)) {
          throw new Error(`Unknown format: ${data}`);
        }
        var challengeBinary = new Uint8Array(5 + data.length || data.byteLength);
        challengeBinary.set(new Uint8Array([87, 69, 66, 68, 65]));
        challengeBinary.set(data, 5);
        info = {
          hash: md5(new Uint8Array(data)),
          challenge: md5(challengeBinary),
          name: filename,
          size: data.length || data.byteLength,
          mimetype,
          ...info
        };
        if (info.hash.startsWith("d41d8cd98f00b204e9800998ecf8427e")) {
          throw new Error("Empty content");
        }
        // Check if file is already known by our system
        let res = await this.$whale.controller.http(`/binaries/upload/${model}/${uuid}/${attribute}`, {
          method: "PUT",
          bodyObject: info
        });
        // Upload is not known
        if (!res.done) {
          await axios.request({
            url: res.url,
            method: res.method,
            data,
            headers: {
              "Content-MD5": res.md5,
              "Content-Type": "application/octet-stream"
            },
            onUploadProgress: progressEvent => {
              if (progressHandler) {
                progressHandler(Math.round((progressEvent.loaded * 100) / progressEvent.total));
              }
            }
          });
        }
        if (progressHandler) {
          progressHandler(100);
        }
        return info;
      },

      async deleteProjectFile(attribute: string, index: number) {
        await this.deleteFile("projects", this.project.uuid, attribute, index, this.project[attribute][index].hash);
        this.project[attribute].splice(index, 1);
      },

      deleteFile(model: string, uuid: string, attribute: string, index: number, hash: string) {
        return this.controller.http(`/binaries/${model}/${uuid}/${attribute}/${index}/${hash}`, { method: "DELETE" });
      },

      /**
       * Update the metadata of a project binary
       * @param attribute project attribute containing binary
       * @param project_binary modified binary (direct reference)
       * @param metadata new metadata value
       */
      async updateProjectBinaryMetadata(attribute: string, project_binary, metadata) {
        // get binary info
        let hash = project_binary.hash;
        let index = this.$whale.project[attribute].indexOf(project_binary);
        if (index == -1) {
          let message = this.$t("project.errors.get_binary");
          alert({ message, type: "error" });
          return;
        }
        // call binary update
        await this.updateBinaryMetadata("projects", attribute, index, hash, metadata).then(() => {
          // update local project if only change succeeded
          project_binary.metadata = metadata;
        });
      },

      /**
       * Update the metadata of a binary
       * @param model
       * @param attribute
       * @param index
       * @param hash
       * @param metadata
       */
      async updateBinaryMetadata(model: string, attribute: string, index: number, hash: string, metadata) {
        return this.$whale.controller.http(`binaries/${model}/${this.project.uuid}/${attribute}/${index}/${hash}`, {
          method: "PUT",
          bodyObject: metadata
        });
      },

      async getProjectBinaryFromHash(hash, attribute, raw = false) {
        let project = this.$whale.project;
        let index = this.getBinaryIndexFromHash(hash, attribute);
        if (index == -1) {
          throw new Error("Error while trying to get project binary info");
        }
        let data = await this.$whale.downloadFromBinaries(`projects/${project.uuid}/${attribute}/${index}`, raw);
        return data;
      },

      getBinaryIndexFromHash(hash, attribute) {
        let project = this.$whale.project;
        let index = project[attribute].map(data => data.hash).indexOf(hash);
        return index;
      },

      setMap(map) {
        this.map = map;
      },

      /**
       * Request shark API
       * @param url
       * @returns
       */
      async shark(url: string, options: any = {}) {
        return this.controller.http(`shark/${url}`, options);
      },

      async railwayStationInformation(bbox: any = [-180, -90, 180, 90]) {
        return this.shark(`utils/railway_station_information?bbox=${bbox.join("%2C")}`, {
          loader: "railway_stations"
        });
      },

      async communeInformation(bbox: any = [-180, -90, 180, 90]) {
        return this.shark(`utils/commune_information?deps=35,44&bbox=${bbox.join("%2C")}`, { loader: "communes" });
      },

      async territoryInformation(bbox: any = [-180, -90, 180, 90], epci: boolean) {
        return this.shark(`utils/territory_information?bbox=${bbox.join("%2C")}&aggregate_epci=${epci}&max=1000`, {
          loader: "communes"
        });
      },

      async numberBikes(city: string, date: string) {
        return this.shark(`utils/number_bikes?city=${city}&date=${date}`, { loader: "bikes" });
      },

      async gtfsRoutes(gtfs: string) {
        return this.shark(`utils/gtfs_routes?gtfs=${gtfs}`, { loader: "routes" });
      },

      async transportLinePopulation(geojson: any, size: number = 300) {
        return this.shark(`demand/transportline?buffer=${size}`, {
          loader: "draw_pt_line",
          method: "POST",
          bodyObject: geojson
        });
      },

      async transportLineOsm(bbox, route_id) {
        return this.shark(`osm/pt_from_polygon?bbox=${bbox}&route_id=${route_id}&amenities=`);
      },

      /**
       * Request starling API
       * @param url
       * @returns
       */
      async starling(url: string, options: any = {}, raw: boolean = false) {
        return this.controller.http(`starling/${url}`, options, raw);
      },

      // simulation

      /**
       * Get the version of Starling used by the API
       * @param {*} context
       * @returns Starling version as a string
       */
      async getStarlingVersion(options = { loader: "overlay" }) {
        return this.starling(`starling/version`, options);
      },

      /**
       * Get the JSON schema of Starling's simulation parameters
       * @returns
       */
      async getParametersSchema() {
        return this.starling(`schemas/parameters`, { loader: "overlay" });
      },

      /**
       * Get the JSON schema of the given Starling model
       * @param model
       * @returns
       */
      async getModelSchemas(model: string) {
        return this.starling(`schemas/model?model_code=${model}`, { loader: "overlay" });
      },

      /**
       * Get network graphs available for the simulation
       * TODO : replace with call to project as for GTFS
       * @returns
       */
      async getNetworkGraphs() {
        return this.starling(`network_graphs/list`, { loader: "overlay" });
      },

      /**
       * Get graphs speeds available for the simulation
       * TODO : replace with call to project as for GTFS
       * @returns
       */
      async getGraphSpeeds() {
        return this.starling(`graph_speeds/list`, { loader: "overlay" });
      },

      /**
       * Run a simulation from the given scenario data
       * @param scenario_data
       * @returns
       */
      async runStarlingSimulation(scenario_data: any) {
        return this.controller.http(`scenarios`, {
          method: "POST",
          bodyObject: { ...scenario_data, arguments: [], project: this.project.uuid },
          loader: "overlay"
        });
      },

      /**
       * Simulate a model use case with Starling
       * @param scenario_data
       * @returns
       */
      async runStarlingUseCase(scenario_data: any) {
        return this.controller.http(`scenarios`, {
          method: "POST",
          bodyObject: {
            ...scenario_data,
            project: this.project.uuid
          }
        });
      },

      // scenarios management

      /**
       * Get the list of the simulation scenarios
       * @returns
       */
      async getScenarioList(useCase: string) {
        try {
          let res = await this.controller.http(
            `scenarios?q=` + encodeURIComponent(`service = '${useCase}' AND project = '${this.project.uuid}'`),
            {
              errorAlert: false
            }
          );
          this.scenarioList = res.results;
          return res.results;
        } catch (err) {
          return [];
        }
      },

      /**
       * Get the list index of the scenario file corresponding to the given metadata.
       * @param file_list
       * @param content
       * @param subject
       * @returns
       */
      getScenarioFileFromMetadata(file_list, content, subject) {
        const index = file_list.findIndex(file_info => {
          return (
            file_info.metadata.content == content && (subject !== undefined || file_info.metadata.subject == subject)
          );
        });
        if (index == -1) {
          throw new Error("No file found");
        }
        return index;
      },

      /**
       * Fetch the scenario visualisation output
       * @param model
       * @param scenario
       * @returns
       */
      async downloadScenarioTrace(scenario: any) {
        const index = this.getScenarioFileFromMetadata(scenario._outputs, "visualisation");
        return this.downloadFromBinaries(`scenarios/${scenario.uuid}/_outputs/${index}`, true);
      },

      /**
       * Fetch the scenario input graph (walk)
       * @param {*} context
       * @param {*} param1
       * @returns scenario json input
       */
      async downloadScenarioGraph(scenario: any) {
        const index = this.getScenarioFileFromMetadata(scenario._inputs, "graph_geometry", "walk");
        return this.downloadFromBinaries(`scenarios/${scenario.uuid}/_inputs/${index}`);
      },

      /**
       * Fetch the scenario input users
       * @param {*} context
       * @param {*} param1
       * @returns scenario json input
       */
      async downloadScenarioUsers(scenario: any) {
        // TODO : improve trace detection using metadata
        let index = this.getScenarioFileFromMetadata(scenario._inputs, "agents", "user");
        return this.downloadFromBinaries(`scenarios/${scenario.uuid}/_inputs/${index}`);
      },

      /**
       * Fetch the scenario files in a compressed archive
       * @param model
       * @param scenario
       * @returns
       */
      async getScenarioArchive(model: string, scenario: string) {
        return this.starling(`scenario/download?model=${model}&scenario=${scenario}`, { loader: "scenarioList" });
      },

      // KPIs

      /**
       * Get model specific KPIs on the given scenario
       * @param scenario
       * @returns
       */
      async getModelKpis(scenario: any) {
        let index = this.getScenarioFileFromMetadata(scenario._outputs, "model_kpis");
        return this.downloadFromBinaries(`scenarios/${scenario.uuid}/_outputs/${index}`);
      },

      // flows

      async aggregateFlows({ method, data, attributes, options }) {
        return this.shark(`flows/aggregate/${method}`, {
          method: "POST",
          bodyObject: {
            data,
            attributes,
            options
          },
          loader: "overlay"
        });
      },

      async getFlowsSummary() {
        return this.shark(`od/summary`, { loader: "flows_database", errorAlert: false });
      },

      async getZoningSummary() {
        return this.shark(`zoning/summary`, { loader: "flows_database", errorAlert: false });
      },

      async getZoningTable(id_zoning: string) {
        return this.shark(`zoning/table?id_zoning=${id_zoning}`, { loader: "overlay" });
      },

      async getFlowTables({
        id_flows,
        id_locations,
        attributes,
        min_count,
        zoning_dataset,
        zoning_selection,
        zoning_ids,
        selection_mode
      }) {
        return this.shark(
          `od/flowmap_tables?id_flows=${id_flows}&id_locations=${id_locations}&attributes=${attributes}&min_count=${min_count}&zoning_dataset=${zoning_dataset}&zoning_selection=${zoning_selection}&zoning_ids=${zoning_ids}&selection_mode=${selection_mode}`,
          { loader: "overlay" }
        );
      },

      async getStarlingTable({ id_starling, attributes }) {
        return this.shark(`od/starling_table?id_starling=${id_starling}&attributes=${attributes}`, {
          loader: "overlay"
        });
      },

      // async scenarios

      async createScenario(scenario_data: any) {
        scenario_data.project = this.project.uuid;
        return this.controller.http(`scenarios`, {
          method: "POST",
          bodyObject: scenario_data
        });
      },

      async runScenario(uuid: string) {
        return this.controller.http(`scenarios/${uuid}/run`, {
          method: "PUT"
        });
      },

      async deleteScenario(uuid: string) {
        return this.controller.http(`scenarios/${uuid}`, {
          method: "DELETE"
        });
      },

      // layers

      /**
       * Get the table of available layers from database
       * @param bbox
       * @returns
       */
      async getLayersTable(bbox: any = { _ne: { lat: 90, lng: 180 }, _sw: { lat: -90, lng: -180 } }) {
        return this.shark(
          `layers/table?ne_lng=${bbox._ne.lng}&ne_lat=${bbox._ne.lat}&sw_lng=${bbox._sw.lng}&sw_lat=${bbox._sw.lat}`,
          { loader: "dbLayers", errorAlert: false }
        );
      },

      // network

      getRoadIsochrones({ point, direction, mode, max_time, speed, center_radius }) {
        return this.shark(
          `offer/isochrone?longitude=${point.coordinates.lng}&latitude=${point.coordinates.lat}&mode=${mode}&direction=${direction}&speed=${speed}&max_time=${max_time}&center_radius=${center_radius}`,
          { loader: "generic" }
        );
      },

      // GTFS database methods

      async getPtNetworksList() {
        let res = await this.controller.http("graphql", {
          method: "POST",
          bodyObject: {
            query: `
            query Q {
              PublicTransportNetworks(query:""){
                results{
                  uuid
                  moa{
                    uuid
                    name
                  }
                  name
                }
              }
            }
          `
          }
        });
        return res.data.PublicTransportNetworks.results;
      },

      async getGtfsList() {
        let res = await this.controller.http("graphql", {
          method: "POST",
          bodyObject: {
            // query includes all objects, but Gtfs.canAct will filter unauthorized objects
            query: `
              query Q {
                PublicTransports(query:"status='READY'"){
                  results{
                    uuid
                    pt_network{
                      uuid
                      moa{
                        uuid
                        name
                      }
                      name
                    }
                    statistics
                    start_date
                    end_date
                    day_types
                  }
                }
              }
            `
          },
          loader: "database_networks"
        });
        return res.data.PublicTransports.results;
      },

      async getGtfs(gtfs_uuid: string) {
        let res = await this.controller.http("graphql", {
          method: "POST",
          bodyObject: {
            query: `
              query Q($uuid: String) {
                Gtfs(uuid: $uuid){
                  uuid
                  pt_network{
                    uuid
                    moa{
                      uuid
                      name
                    }
                    name
                  }
                  statistics
                  start_date
                  end_date
                  day_types
                  status
                }
              }
            `,
            variables: {
              uuid: gtfs_uuid
            }
          },
          loader: "database_networks"
        });
        return res.data.Gtfs;
      },

      async getGtfsRoutesAndStops(gtfs_uuid: string) {
        let routes_and_stops = await Promise.all([
          this.requestWithContinuationToken(`public_transports/${gtfs_uuid}/gtfs_routes`),
          this.requestWithContinuationToken(`public_transports/${gtfs_uuid}/gtfs_stops`)
        ])

        return {
          routes: routes_and_stops[0],
          stops: routes_and_stops[1]
        }
      },

      async createProjectGtfs(uuid: string, pt_network: string, dist_units: string) {
        return await this.controller.http(`public_transport_networks/${pt_network}/public_transports`, {
          method: "POST",
          bodyObject: {
            uuid,
            pt_network,
            dist_units,
            visibility: "project"
          }
        });
      },

      async requestWithContinuationToken(url, options, max_calls=100) {
        let items = [];
        let done = false;
        let query = ``
        let nb_calls = 0;
        let response;
        while (!done && nb_calls < max_calls) {
          nb_calls += 1;
          response = await this.controller.http(`${url}${query}`)
          items.push(response.results)
          if (response.continuationToken) {
            query = `?q=OFFSET "${response.continuationToken}"`;
          } else {
            done = true
          }
        }
        if (!done) {
          throw new Error("Reached maximum number of calls for request with continuation token")
        }
        items = items.flat(1)
        return items
      } 
    },
  });
  instance.login();
  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const WhalePlugin = {
  install(Vue, options) {
    Vue.prototype.$whale = useWhale(options);
  }
};

export type Feature = "GPS" | "STARLING" | "CAPUCINE";
