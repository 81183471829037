<!--
Dialog that sits next to the main navigation drawer
-->

<template>
  <kite-dialog v-bind="$attrs" hide-overlay persistent no-click-animation :value="dialog">
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>{{ $t("network.info_dialog.title") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn large icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title fixed-tabs :slider-size="SLIDER_SIZE">
            <v-tabs-slider :color="SLIDER_COLOR" />
            <v-tab v-for="(item, i) in tabs[infoDialogContentType]" :key="i" :tab-value="item.key">
              {{ $t(item.text) }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card height="79vh" class="overflow-auto">
        <component :is="info_component" :content="infoDialogContent" :tab="tab" />
      </v-card>
    </v-card>
  </kite-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { SLIDER_SIZE, SLIDER_COLOR } from "@/global";
import { mapState, mapGetters, mapActions } from "vuex";
import KiteDialog from "@/components/kite/base/kite_dialog.vue";
import GtfsInfo from "./gtfs_info.vue";
import RouteInfo from "./route_info.vue";
import StopInfo from "./stop_info.vue";

export default Vue.component("network-info-dialog", {
  components: { GtfsInfo, RouteInfo, StopInfo },

  data() {
    return {
      tab: null,
      tabs: {
        gtfs: [{ key: "statistics", text: "network.tabs.statistics.title" }],
        route: [
          { key: "statistics", text: "network.tabs.statistics.title" },
          { key: "variants", text: "network.tabs.variants.title" }
        ],
        stop: [{ key: "statistics", text: "network.tabs.statistics.title" }]
      },
      SLIDER_SIZE,
      SLIDER_COLOR
    };
  },
  watch: {
    infoDialogContentType(value) {
      if (this.infoDialogContentType) {
        this.tab = this.tabs[this.infoDialogContentType][0].key;
      }
    }
  },

  computed: {
    ...mapState("network", ["infoDialogContent"]),
    ...mapGetters("network", ["infoDialogContentType"]),
    dialog() {
      if (this.infoDialogContent) {
        return true;
      } else {
        return false;
      }
    },
    info_component() {
      if (this.infoDialogContentType) {
        return `${this.infoDialogContentType}-info`;
      } else {
        return undefined;
      }
    }
  },

  methods: {
    ...mapActions("network", ["setInfoDialogContent"]),
    closeDialog() {
      this.setInfoDialogContent(null);
    }
  }
});
</script>
