<!--
  Main network page
-->

<template>
  <div>
    <kite-drawer-content>
      <v-row class="mt-2 ml-2" justify="center">
        <v-col>
          <add-network-dialog />
        </v-col>
        <v-col>
          <road-isochrones />
        </v-col>
      </v-row>
      <v-card-text>
        <v-divider />
      </v-card-text>
      <v-card-text v-if="currentNetworkView">
        <v-row>
          <v-col cols="10">
            <h2 :style="{ color: $vuetify.theme.themes.light.primary }">
              {{ currentNetworkView?.name }}
            </h2>
          </v-col>
          <v-col cols="1">
            <kite-edit-text
              :text="currentNetworkView.name"
              @save="currentNetworkView.name = $event"
              :rules="name_rules"
              :counter="name_max_size"
            >
              <v-icon small color="primary">edit</v-icon>
            </kite-edit-text>
          </v-col>
          <v-spacer />
          <v-col cols="1">
            <kite-table-action
              @click="setInfoDialogContent(currentNetworkView.gtfs)"
              icon="open_in_new"
              :tooltip="$t('basic_dialogs.open')"
            />
          </v-col>
        </v-row>
        <p>
          {{
            `${currentNetworkView?.gtfs.pt_network.moa.name} - ${currentNetworkView?.gtfs.pt_network.name} : ${gtfs_dates}`
          }}
        </p>
      </v-card-text>
      <v-card class="mt-2 mx-1" elevation="0" tile>
        <v-tabs v-model="tab" background-color="primary" class="elevation-2" dark grow hide-slider>
          <!--
            <v-tab :key="0" :href="`#tab-0`">
              {{ $t("network.networks") }}
            </v-tab>
            <v-tab-item :key="0" :value="'tab-0'">
              <v-data-table
                :headers="headers"
                :items="networkViews"
                :no-data-text="$t('network.table.no_data')"
                disable-pagination
                hide-default-footer
                disable-sort
                :loading="async.gtfsDraw > 0"
              >
                <template v-for="header in headers" v-slot:[`header.${header.value}`]>
                  {{ $t("network.table.headers." + header.text) }}
                </template>
                <template v-slot:[`item.selection`]="{ item }">
                  <v-simple-checkbox
                    :value="item.id == currentNetworkViewId"
                    color="black"
                    on-icon="radio_button_checked"
                    off-icon="radio_button_unchecked"
                    :ripple="false"
                    @click="networkViewSelection(item)"
                  />
                </template>
                <template v-slot:item.name="{ item }">
                  <kite-edit-text
                    :text="item.name"
                    :rules="name_rules"
                    :counter="name_max_size"
                    @save="item.rename($event)"
                  >
                    {{ truncateString(item.name, 34) }}
                  </kite-edit-text>
                </template>
                <template v-slot:item.actions="{ item }">
                  <kite-table-action
                    icon="clear"
                    @click="removeNetworkView(item)"
                    :tooltip="$t('basic_dialogs.remove')"
                  />
                </template>
              </v-data-table>
            </v-tab-item>
            -->
          <v-tab :key="0" :href="`#tab-0`"> {{ routesTableTitle }} </v-tab>
          <v-tab-item :key="0" :value="'tab-0'">
            <network-table
              v-model="selectedRoutes"
              :headers="routes_headers"
              :items="currentNetworkView?.routes"
              disable-sort
              item-key="route_id"
            >
              <template v-slot:item.name="{ item }">
                <route-chip :route="item" />
              </template>
              <template v-slot:item.route_type="{ item }">
                <route-type-icon :route-type="item.route_type" tooltip />
              </template>
            </network-table>
          </v-tab-item>
          <v-tab :key="1" :href="`#tab-1`"> {{ stopsTableTitle }} </v-tab>
          <v-tab-item :key="1" :value="'tab-1'">
            <network-table
              v-model="selectedStops"
              :headers="stops_headers"
              :items="currentNetworkView?.stops"
              sort-by="stop_name"
              item-key="stop_id"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </kite-drawer-content>
    <network-info-dialog />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { truncateString } from "@/functions-tools";
import { nameRequired, nameLengthRule, name_max_size } from "@/validation";
import AddNetworkDialog from "./add/add_network_dialog.vue";
import RoadIsochrones from "./road_isochrones.vue";
import RouteTypeIcon from "@/components/transports/route_type_icon.vue";
import NetworkInfoDialog from "./info_dialog/network_info_dialog.vue";
import RouteChip from "@/components/transports/route_chip.vue";
import NetworkTable from "./network_table.vue";

export default Vue.component("network-page", {
  components: {
    AddNetworkDialog,
    RoadIsochrones,
    RouteTypeIcon,
    NetworkInfoDialog,
    RouteChip,
    NetworkTable
  },

  data: function () {
    return {
      tab: 0,
      routes_headers: [
        { value: "name", text: "name" },
        { value: "route_type", text: "type", align: "center" },
        { value: "actions", text: "actions", align: "center" }
      ],
      stops_headers: [
        { value: "stop_name", text: "name", sortable: true },
        { value: "actions", text: "actions", align: "center", sortable: false }
      ],
      name_rules: [nameRequired, nameLengthRule],
      name_max_size
    };
  },

  computed: {
    ...mapState("layers", ["map"]),
    ...mapState("network", ["currentNetworkView"]),
    selectedRoutes: {
      get() {
        return this.$store.state.network.selectedRoutes;
      },
      set(value) {
        this.setSelectedRoutes(value);
      }
    },
    selectedStops: {
      get() {
        return this.$store.state.network.selectedStops;
      },
      set(value) {
        this.setSelectedStops(value);
      }
    },
    gtfs_dates() {
      let gtfs = this.currentNetworkView.gtfs;
      return (
        new Date(gtfs.start_date).toLocaleString(this.language, { dateStyle: "short" }) +
        " - " +
        new Date(gtfs.end_date).toLocaleString(this.language, { dateStyle: "short" })
      );
    },
    routesTableTitle() {
      let nb_routes = this.currentNetworkView?.routes.length
      let title = this.$t("network.table.routes")
      if (nb_routes !== undefined) {
        title += ` (${nb_routes})`
      }
      return title
    },
    stopsTableTitle() {
      let nb_stops = this.currentNetworkView?.stops.length
      let title = this.$t("network.table.stops")
      if (nb_stops!== undefined) {
        title += ` (${nb_stops})`
      }
      return title
    }
  },
  methods: {
    ...mapActions("network", ["setSelectedRoutes", "setSelectedStops", "setInfoDialogContent"]),
    truncateString
  }
});
</script>
