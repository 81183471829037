<template>
  <div>
    <kite-drawer-content classprop="overflow-auto pr-4 pb-4 pl-4">
      <v-row class="mt-2" justify="center">
        <v-col>
          <add-layer-menu />
        </v-col>
        <v-col class="ml-n4"><map-manager-menu /></v-col>
        <v-col class="ml-n4">
          <v-menu offset-y max-height="50vh" :close-on-content-click="false" open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="primary" large rounded v-bind="attrs" v-on="on">
                <v-icon left>map</v-icon> {{ $t("map_layers.base_layer") }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  ><h5>{{ $t("map_layers.opacity") }}</h5>
                  <v-slider v-model="baseLayerOpacity" min="0" max="1" step="0.1" hide-details
                /></v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item v-for="item in base_layers" :key="item" link class="py-2">
                <v-list-item-title @click="changeBaseLayer(item)"
                  ><h5>{{ item }}</h5>
                  <v-img :src="'static/baselayers/' + item + '.png'" :alt="item" height="50px" width="200px"
                /></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-tabs v-model="tab" background-color="primary" class="elevation-2" dark grow hide-slider>
          <v-tab :key="0" :href="`#tab-0`">
            {{ $t("map_layers.kite_layers") }}
          </v-tab>
          <v-tab-item :key="0" :value="'tab-0'">
            <!-- Layer table -->
            <v-card class="overflow-auto pa-0" height="calc(100vh - 235px)" tile>
              <layer-table :layerItems="layerItems" />
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </kite-drawer-content>
  </div>
</template>

<script>
import Vue from "vue";
import { BASE_LAYERS } from "@/global";
import LayerTable from "./layer_table.vue";
import AddLayerMenu from "./add_layer_menu.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import KiteDrawerContent from "@/components/kite/base/kite_drawer_content.vue";
import MapManagerMenu from "@/components/kite/map_views/map_manager_menu.vue";

export default Vue.component("layers-page", {
  components: {
    KiteDrawerContent,
    LayerTable,
    AddLayerMenu,
    MapManagerMenu
  },

  data: function () {
    return {
      tab: 0,
      base_layers: this.baseLayersList()
    };
  },

  mounted() {
    this.getFullDatabaseLayersTable();
    this.getDatabaseGtfs();
  },

  computed: {
    ...mapState("layers", ["zList", "allLayers", "map", "addDialog", "baseLayer"]),
    ...mapGetters("layers", ["displayedLayers", "getLayerView"]),
    baseLayerOpacity: {
      get() {
        return this.$store.state.layers.baseLayerOpacity;
      },
      set(newValue) {
        this.$store.commit("layers/CHANGE_BASE_LAYER_OPACITY", newValue);
      }
    },
    layerItems() {
      return this.displayedLayers.map(id => this.getLayerView(id)).reverse();
    }
  },

  methods: {
    ...mapActions("layers", ["getFullDatabaseLayersTable"]),
    ...mapActions("network", ["getDatabaseGtfs"]),
    baseLayersList() {
      let base_layers = BASE_LAYERS.map(layer => layer.name);
      return base_layers;
    },
    changeBaseLayer(item) {
      this.$store.commit("layers/CHANGE_BASE_LAYER", item);
    }
  }
});
</script>
<style scoped>
h5 {
  color: var(--v-primary-base);
}
</style>
